import React, { useEffect, useState } from "react";
import { apiUrl } from "constants/index";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from 'moment-timezone';
import Chart from "react-apexcharts";
import axios from 'axios';
import Plant from "assets/images/dashboard.svg";
import BarChartAccumulativeSKUProduction from "components/chart/BarChartAccumulativeSKUProduction";
import BarChartAccumulativeProduction from "components/chart/BarChartAccumulativeProduction";
import RemainingProductionPage2 from "components/RemainingProductionPage2";
import BarChartStatusMachine from "components/chart/BarChartStatusMachine";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import db from "firebase-config";
import LensIcon from '@mui/icons-material/Lens';
import Tooltip from '@mui/material/Tooltip';
import { Link as RouterLink, useNavigate } from 'react-router-dom';


var token_url = apiUrl.TOKEN;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),

  color: theme.palette.text.secondary,
}));



function getValueByKey(object, row) {
  return object[row];
}

function Production() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [fryer1, setFryer1] = useState(true);
  const [fryer2, setFryer2] = useState(true);
  const [fryer3, setFryer3] = useState(true);
  const [skuDetailsKeys, setSKUDetailsKeys] = useState();
  const [responseObject, setResponseObject] = useState();
  const [barSeries, setBarSeries] = useState();
  const [targetNowSeries, setTargetNowSeries] = useState();
  const [overallTargetNow, setOverallTargetNow] = useState();
  const [statusMachine, setStatusMachine] = useState([]);
  const [skuOverview, setSkuOverview] = useState({
    WAITING: "",
    RUNNING: "",
    COMPLETED: "",
    TARGET: ""
  });
  const [totalPerformance, setTotalPerformance] = useState({
    TARGET_PRODUCTION_QUANTITY: "",
    PRODUCTION_UNIT: "",
    ACTUAL_WEIGHT: ""
  });
  const [oee, setOee] = useState({
    OEE: "",
    A: "",
    P: "",
    Q: "",
    OEE_TARGET: "",
    A_TARGET: "",
    P_TARGET: "",
    Q_TARGET: "",
  });
  const [repeater, setRepeater] = useState(0)
  const [no_data, setNo_data] = useState(false);
  const today = new Date();
  const hourago = new Date(today.getTime());
  const timestr = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');
  localStorage.setItem('timestr', timestr);
  const MINUTE_MS = 60000;

  const [runtime, setRuntime] = useState(false);


  // ฟังก์ชั่นเช็คtoken
  const checkToken = async () => {
    let access_exp = localStorage.getItem("rb_ac_exp");
    let refresh_exp = localStorage.getItem("rb_rf_exp");
    //console.log('access_exp: ' + access_exp);
    //console.log('refresh_exp: ' + refresh_exp);
    if (refresh_exp < Date.now() / 1000) {
      //console.log("refresh is expiried");
      // alert('Please log in again')
      localStorage.clear();
      window.location = "/Login";
    } else if (access_exp < Date.now() / 1000) {
      const refresh = localStorage.getItem("rb_rf_token");

      let data = {
        refresh: refresh,
      };
      let result = await axios.post(token_url, data);
      //console.log("got new access_token!");
      localStorage.setItem("rb_ac_token", result.data.access);
    }
  };

  useEffect(() => {
    async function fetchMyAPI() {
      // check access token expiry
      checkToken();
      var get_datakorat = `${apiUrl.GET_SPECIFIC_LINE_DATA}${timestr}/` // date format must be YYYY-MM-DD, eg. 2022-06-16
      await axios.get(get_datakorat, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
          "X-xss-protection": "1; mode=block;",
          "X-frame-options": "SAMEORIGIN",
          "X-content-type-options": "nosniff",
          "Cache-Control": "no-cache, private",
          "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
          "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
        }
      }
      )
        .then(response => {
          //console.log(response)
          if (response.status == 200) {
            //console.log("sku: " + response.data.SKU_OVERVIEW);
            setSkuOverview({
              WAITING: response.data.SKU_OVERVIEW.WAITING,
              RUNNING: response.data.SKU_OVERVIEW.RUNNING,
              COMPLETED: response.data.SKU_OVERVIEW.COMPLETED,
              TARGET: response.data.SKU_OVERVIEW.TARGET
            })
            setTotalPerformance({
              TARGET_PRODUCTION_QUANTITY: response.data.PERFORMANCE[0].TARGET_PRODUCTION_QUANTITY,
              PRODUCTION_UNIT: response.data.PERFORMANCE[0].PRODUCTION_UNIT,
              ACTUAL_WEIGHT: response.data.PERFORMANCE[0].ACTUAL_WEIGHT
            })
            try {
              setOee({
                OEE: response.data.OEE.OEE != 'NA' ? response.data.OEE[0].OEE : 0,
                A: response.data.OEE.A != 'NA' ? response.data.OEE[0].A : 0,
                P: response.data.OEE.P != 'NA' ? response.data.OEE[0].P : 0,
                Q: response.data.OEE.Q != 'NA' ? response.data.OEE[0].Q : 0,
                OEE_TARGET: response.data.OEE[0].OEE_TARGET,
                A_TARGET: response.data.OEE[0].A_TARGET,
                P_TARGET: response.data.OEE[0].P_TARGET,
                Q_TARGET: response.data.OEE[0].Q_TARGET,
              })
            }
            catch {
              setOee({
                OEE: 0,
                A: 0,
                P: 0,
                Q: 0,
                OEE_TARGET: 0,
                A_TARGET: 0,
                P_TARGET: 0,
                Q_TARGET: 0,
              })
            }
            let keys = []

            setSKUDetailsKeys(['LINE7', 'LINE8', 'LINE9', 'LINE10'])
            setResponseObject(response.data)
            let objBarSeries = {}
            getValueByKey(responseObject, 'BAR_CHART_WORK_CENTERS').map(index => (
              Object.assign(objBarSeries, index)
            ))
            setBarSeries(objBarSeries)
            let objTargetNowSeries = {}
            getValueByKey(responseObject, 'TARGET_NOW').map(index => (
              Object.assign(objTargetNowSeries, index)
            ))
            setTargetNowSeries(objTargetNowSeries)
            let targetNow = 0
            let targetNowObj = Object.values(targetNowSeries)
            targetNowObj.map(index => (
              targetNow += index
            ))
            setOverallTargetNow(targetNow)
            setLoading(false)
            setNo_data(1)
          }
        })
        .catch(error => {
          setNo_data(2)
          console.log('error: ', error)

        })
    }
    fetchMyAPI()

    const interval = setInterval(() => fetchMyAPI(), 1 * 60 * 1000)
    return () => {
      clearInterval(interval);
    }

  }, [oee]);

  //console.log(skuOverview);

  useEffect(() => {
    const getPostFromFirebase = [];
    const subscriber = db
      .collection("test_meskr_mqtt_each_machine")
      .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          getPostFromFirebase.push({
            ...doc.data(),
            id: doc.id,
          });
        });
        setStatusMachine(getPostFromFirebase);

      });

    // return cleanup function
    return () => subscriber();
  }, [statusMachine]);


  // init machine runtime
  useEffect(() => {
    checkToken();
    axios.get(`${apiUrl.MACHINE_RUNTIME}${timestr}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
        "X-xss-protection": "1; mode=block;",
        "X-frame-options": "SAMEORIGIN",
        "X-content-type-options": "nosniff",
        "Cache-Control": "no-cache, private",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"

      }
    }
    ).then(response => {
      if (response.status === 200) {
        setFryer1(response.data.EFRB5108)
        setFryer2(response.data.EFRB5107)
        setFryer3(response.data.EFRB5106)
        setRuntime(1)
      }
    }).catch(error => {
      console.log('error: ', error)
      setRuntime(2)

    })
  }, []);
  // Machine Runtime every 1 minute
  useEffect(() => {
    const interval = setInterval(() => {
      const checkToken = async () => {
        let access_exp = localStorage.getItem("rb_ac_token");
        let refresh_exp = localStorage.getItem("rb_rf_token");
        let timestr = localStorage.getItem("timestr");
        if (refresh_exp < Date.now() / 1000) {
          // console.log("refresh is expiried");
          // alert('Please log in again')
          localStorage.clear();
          window.location = "/login";
        } else if (access_exp < Date.now() / 1000) {
          const refresh = localStorage.getItem("rb_rf_token");
        }
      }
      checkToken();
      axios.get(`${apiUrl.MACHINE_RUNTIME}${timestr}/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
          "X-xss-protection": "1; mode=block;",
          "X-frame-options": "SAMEORIGIN",
          "X-content-type-options": "nosniff",
          "Cache-Control": "no-cache, private",
          "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
          "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"

        }
      }
      ).then(response => {
        if (response.status === 200) {
          setFryer1(response.data.EFRB5108)
          setFryer2(response.data.EFRB5107)
          setFryer3(response.data.EFRB5106)
        }
      }).catch(error => {
        console.log('error: ', error)

      })
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  useEffect(() => {
    if (`${localStorage.getItem('rb_Admin')}` == 'false' && `${localStorage.getItem('rb_Staff')}` == 'false') {
      navigate('/check_list', { replace: true });
    } else if (`${localStorage.getItem('rb_Admin')}` == 'false' && `${localStorage.getItem('rb_Staff')}` == 'true') {
      navigate('/preparation', { replace: true });
    }
  }, []);

  const getColorOEE = () => {
    let color;
    try {
      if (oee.OEE >= 85) {
        color = "#4caf50"
      } else if (oee.OEE >= 75 && oee.OEE <= 84.99) {
        color = "#ffeb3b"
      } else if (oee.OEE < 75) {
        color = "#e53935"
      }
      return color;
    }
    catch {
      console.log()
    }
  }

  const getColorAVA = () => {
    let color;
    try {
      if (oee.A >= 92) {
        color = "#4caf50"
      } else if (oee.A >= 82 && oee.A <= 91.99) {
        color = "#ffeb3b"
      } else if (oee.A < 82) {
        color = "#e53935"
      }
      return color;
    }
    catch {
      console.log()
    }
  }

  const getColorPER = () => {
    let color;
    try {
      if (oee.P >= 95) {
        color = "#4caf50"
      } else if (oee.P >= 85 && oee.P <= 94.99) {
        color = "#ffeb3b"
      } else if (oee.P < 85) {
        color = "#e53935"
      }
      return color;
    }
    catch {
      console.log()
    }
  }

  const getColorQUA = () => {
    let color;
    try {
      if (oee.Q >= 98) {
        color = "#4caf50"
      } else if (oee.Q >= 95 && oee.Q <= 97.99) {
        color = "#ffeb3b"
      } else if (oee.Q < 95) {
        color = "#e53935"
      }
      return color;
    }
    catch {
      console.log()
    }
  }

  let color1 = []
  let color2 = []
  let color3 = []
  let color4 = []

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'Multihead1' && statusMachine[i].IS_CONNECTED == false) {
      color1.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead1' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS == 'Production') {
      color1.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead1' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS != 'Production' && statusMachine[i].MACHINE_ERROR_CODE_DESCRIPTION != 'Zero Error') {
      color1.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead1' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS != 'Production' && statusMachine[i].MACHINE_ERROR_CODE_DESCRIPTION == 'Zero Error') {
      color1.push('#9e9e9e')
    }
    //console.log(color1)
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'Multihead2' && statusMachine[i].IS_CONNECTED == false) {
      color2.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead2' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS == 'Production') {
      color2.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead2' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS != 'Production' && statusMachine[i].MACHINE_ERROR_CODE_DESCRIPTION != 'Zero Error') {
      color2.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead2' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS != 'Production' && statusMachine[i].MACHINE_ERROR_CODE_DESCRIPTION == 'Zero Error') {
      color2.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'Multihead3' && statusMachine[i].IS_CONNECTED == false) {
      color3.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead3' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS == 'Production') {
      color3.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead3' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS != 'Production' && statusMachine[i].MACHINE_ERROR_CODE_DESCRIPTION != 'Zero Error') {
      color3.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead3' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS != 'Production' && statusMachine[i].MACHINE_ERROR_CODE_DESCRIPTION == 'Zero Error') {
      color3.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'Multihead4' && statusMachine[i].IS_CONNECTED == false) {
      color4.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead4' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS == 'Production') {
      color4.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead4' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS != 'Production' && statusMachine[i].MACHINE_ERROR_CODE_DESCRIPTION != 'Zero Error') {
      color4.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'Multihead4' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATUS != 'Production' && statusMachine[i].MACHINE_ERROR_CODE_DESCRIPTION == 'Zero Error') {
      color4.push('#9e9e9e')
    }
  }

  let color7 = []
  let color8 = []
  let color9 = []
  let color10 = []

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'IQF7' && statusMachine[i].IS_CONNECTED == false) {
      color7.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF7' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == true) {
      color7.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF7' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == false && statusMachine[i].FAULT_SUMMARY == true) {
      color7.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF7' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == false && statusMachine[i].FAULT_SUMMARY == false) {
      color7.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'IQF8' && statusMachine[i].IS_CONNECTED == false) {
      color8.push('#fafafa') // ขาว
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF8' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == true) {
      color8.push('#4caf50') //เขียว
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF8' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == false && statusMachine[i].FAULT_SUMMARY == true) {
      color8.push('#f44336') //แดง
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF8' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == false && statusMachine[i].FAULT_SUMMARY == false) {
      color8.push('#9e9e9e') //เทา
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'IQF9' && statusMachine[i].IS_CONNECTED == false) {
      color9.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF9' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == true) {
      color9.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF9' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == false && statusMachine[i].FAULT_SUMMARY == true) {
      color9.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF9' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == false && statusMachine[i].FAULT_SUMMARY == false) {
      color9.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'IQF10' && statusMachine[i].IS_CONNECTED == false) {
      color10.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF10' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == true) {
      color10.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF10' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == false && statusMachine[i].FAULT_SUMMARY == true) {
      color10.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'IQF10' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].BELT_RUNNING == false && statusMachine[i].FAULT_SUMMARY == false) {
      color10.push('#9e9e9e')
    }
  }

  let color5106 = []
  let color5107 = []
  let color5108 = []

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'EFRB5106' && statusMachine[i].IS_CONNECTED == false) {
      color5106.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'EFRB5106' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5106.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'EFRB5106' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5106.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'EFRB5106' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5106.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'EFRB5107' && statusMachine[i].IS_CONNECTED == false) {
      color5107.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'EFRB5107' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5107.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'EFRB5107' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5107.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'EFRB5107' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5107.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'EFRB5108' && statusMachine[i].IS_CONNECTED == false) {
      color5108.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'EFRB5108' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5108.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'EFRB5108' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5108.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'EFRB5108' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5108.push('#9e9e9e')
    }
  }

  let colorSuperheat1 = []
  let colorSuperheat2 = []

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'Superheat1' && statusMachine[i].IS_CONNECTED == false) {
      colorSuperheat1.push('#fafafa')  // ขาว
    }
    if (statusMachine[i].MACHINE_NAME == 'Superheat1' && statusMachine[i].IS_CONNECTED == true && ((statusMachine[i].MACHINE_STATE == 6) || (statusMachine[i].MACHINE_STATE == 7))) {
      colorSuperheat1.push('#4caf50') // เขียว
    }
    if (statusMachine[i].MACHINE_NAME == 'Superheat1' && statusMachine[i].IS_CONNECTED == true && ((statusMachine[i].MACHINE_STATE == 1) || (statusMachine[i].MACHINE_STATE == 2) || (statusMachine[i].MACHINE_STATE == 4))) {
      colorSuperheat1.push('#f44336') // แดง
    }
    if (statusMachine[i].MACHINE_NAME == 'Superheat1' && statusMachine[i].IS_CONNECTED == true && ((statusMachine[i].MACHINE_STATE == 3) || (statusMachine[i].MACHINE_STATE == 5) || (statusMachine[i].MACHINE_STATE == 8) || (statusMachine[i].MACHINE_STATE == 9) || (statusMachine[i].MACHINE_STATE == 10) || (statusMachine[i].MACHINE_STATE == 11) || (statusMachine[i].MACHINE_STATE == 12))) {
      colorSuperheat1.push('#9e9e9e') // เทา
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'Superheat2' && statusMachine[i].IS_CONNECTED == false) {
      colorSuperheat2.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'Superheat2' && statusMachine[i].IS_CONNECTED == true && ((statusMachine[i].MACHINE_STATE == 6) || (statusMachine[i].MACHINE_STATE == 7))) {
      colorSuperheat2.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'Superheat2' && statusMachine[i].IS_CONNECTED == true && ((statusMachine[i].MACHINE_STATE == 1) || (statusMachine[i].MACHINE_STATE == 2) || (statusMachine[i].MACHINE_STATE == 4))) {
      colorSuperheat2.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'Superheat2' && statusMachine[i].IS_CONNECTED == true && ((statusMachine[i].MACHINE_STATE == 3) || (statusMachine[i].MACHINE_STATE == 5) || (statusMachine[i].MACHINE_STATE == 8) || (statusMachine[i].MACHINE_STATE == 9) || (statusMachine[i].MACHINE_STATE == 10) || (statusMachine[i].MACHINE_STATE == 11) || (statusMachine[i].MACHINE_STATE == 12))) {
      colorSuperheat2.push('#9e9e9e')
    }
  }


  let color5024 = []
  let color5047 = []
  let color5048 = []

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'CKT5024' && statusMachine[i].IS_CONNECTED == false) {
      color5024.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'CKT5024' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5024.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'CKT5024' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5024.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'CKT5024' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5024.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'FLC5047' && statusMachine[i].IS_CONNECTED == false) {
      color5047.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'FLC5047' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5047.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'FLC5047' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5047.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'FLC5047' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5047.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'FLC5048' && statusMachine[i].IS_CONNECTED == false) {
      color5048.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'FLC5048' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5048.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'FLC5048' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5048.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'FLC5048' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5048.push('#9e9e9e')
    }
  }

  let color5083 = []
  let color5084 = []
  let color5085 = []
  let color5086 = []
  let color5087 = []
  let color5088 = []

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'SMD5083' && statusMachine[i].IS_CONNECTED == false) {
      color5083.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5083' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5083.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5083' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5083.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5083' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5083.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'SMD5084' && statusMachine[i].IS_CONNECTED == false) {
      color5084.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5084' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5084.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5084' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5084.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5084' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5084.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'SMD5085' && statusMachine[i].IS_CONNECTED == false) {
      color5085.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5085' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5085.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5085' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5085.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5085' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5085.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'SMD5086' && statusMachine[i].IS_CONNECTED == false) {
      color5086.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5086' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5086.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5086' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5086.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5086' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5086.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'SMD5087' && statusMachine[i].IS_CONNECTED == false) {
      color5087.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5087' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5087.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5087' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5087.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5087' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5087.push('#9e9e9e')
    }
  }

  for (let i = 0; i < statusMachine.length; i++) {
    if (statusMachine[i].MACHINE_NAME == 'SMD5088' && statusMachine[i].IS_CONNECTED == false) {
      color5088.push('#fafafa')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5088' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE == 32) {
      color5088.push('#4caf50')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5088' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM >= 1) {
      color5088.push('#f44336')
    }
    if (statusMachine[i].MACHINE_NAME == 'SMD5088' && statusMachine[i].IS_CONNECTED == true && statusMachine[i].MACHINE_STATE != 32 && statusMachine[i].ALARM == 0) {
      color5088.push('#9e9e9e')
    }
  }

  if (Array.isArray(oee)) {
    console.log(oee.pop());
  } else {
    console.log('The value is NOT an array')
  }




  return (
    <>
      <Helmet>
        <title>Production | MES Korat Fur2</title>
      </Helmet>
      <Grid
        container spacing={1}
        direction="row"
        alignItems="top"
        justifyContent="center"
        style={{ minHeight: '78vh', maxHeight: '100vh', flexGrow: 1, paddingLeft: 25, paddingTop: 75 }}
      >
        {/* Top row */}
        <Grid id="top-row" container sx={{ pt: 2.2 }} spacing={1}>
          <Grid xs={12}>
            <Item sx={() => ({
              textAlign: 'left',
              width: '98%',
              height: '50%',
            })}>
              <Box mb={20}>
                <Grid container spacing="0px" alignItems="left" justifyContent="left" >
                  <Grid item xs={1.5} style={{ position: "relative", top: "5px", left: "20px" }}>
                    {/* OEE */}
                    <Typography color="#17b341" fontSize={'18px'} fontWeight="bold" align='left'>
                      OEE
                    </Typography>
                    <Box sx={{ display: 'block' }}>
                      {(loading === true || oee.OEE === 'No data')
                        ? <Chart
                          options={{
                            chart: {
                              type: 'radialBar',
                              offsetY: -20,
                              offsetX: -40,
                              parentHeightOffset: 0,
                              toolbar: {
                                show: false
                              }
                            },
                            plotOptions: {
                              radialBar: {
                                hollow: {
                                  margin: 0,
                                  size: "60%",
                                },

                                dataLabels: {
                                  showOn: "always",
                                  name: {
                                    offsetY: 20,
                                    show: true,
                                    color: "#888",
                                    fontSize: "14px",
                                    fontFamily: 'CPF_Imm_Sook'
                                  },
                                  value: {
                                    offsetY: -10,
                                    color: "#111",
                                    fontSize: "24px",
                                    fontFamily: 'CPF_Imm_Sook',
                                    show: true
                                  }
                                }
                              }
                            },
                            labels: ['Target 85%'],
                            colors: [getColorOEE()],
                            fill: {
                              type: "gradient",
                              gradient: {
                                shade: "dark",
                                type: "vertical",
                                gradientToColors: [getColorOEE()],
                                stops: [0, 100]
                              }
                            },
                          }}
                          series={[0]}
                          type="radialBar"
                          width="120%"
                          height="120%"
                        />
                        :
                        <Chart
                          options={{
                            chart: {
                              type: 'radialBar',
                              offsetY: -20,
                              offsetX: -40,
                              parentHeightOffset: 0,
                              toolbar: {
                                show: false
                              }
                            },
                            plotOptions: {
                              radialBar: {
                                hollow: {
                                  margin: 0,
                                  size: "60%",
                                },

                                dataLabels: {
                                  showOn: "always",
                                  name: {
                                    offsetY: 20,
                                    show: true,
                                    color: "#888",
                                    fontSize: "14px",
                                    fontFamily: 'CPF_Imm_Sook'
                                  },
                                  value: {
                                    offsetY: -10,
                                    color: "#111",
                                    fontSize: "24px",
                                    fontFamily: 'CPF_Imm_Sook',
                                    show: true
                                  }
                                }
                              }
                            },
                            labels: ['Target 85%'],
                            colors: [getColorOEE()],
                            fill: {
                              type: "gradient",
                              gradient: {
                                shade: "dark",
                                type: "vertical",
                                gradientToColors: [getColorOEE()],
                                stops: [0, 100]
                              }
                            },
                          }}
                          series={[Math.round(oee.OEE != 'NA' ? oee.OEE : 0)]}
                          type="radialBar"
                          width="120%"
                          height="120%"
                        />
                      }
                    </Box>
                  </Grid>
                  <Grid item xs={1} style={{ position: "relative", top: "5px", left: "20px" }}>
                    <Grid container>
                      {/* A */}
                      <Grid item>
                        <Typography color="black" fontSize={'15px'} fontWeight="bold" align='left' style={{ position: "relative", top: "5px", left: "-40px" }}>
                          AVA
                        </Typography>
                        <Box sx={{ display: 'block', position: "relative" }}>
                          {(loading === true || oee.A === 'No data')
                            ? <Chart
                              options={{
                                chart: {
                                  type: 'radialBar',
                                  offsetY: -25,
                                  offsetX: -40,
                                  parentHeightOffset: 0,
                                  toolbar: {
                                    show: false
                                  }
                                },
                                plotOptions: {
                                  radialBar: {
                                    hollow: {
                                      margin: 0,
                                      size: "60%",
                                    },

                                    dataLabels: {
                                      showOn: "always",
                                      name: {
                                        offsetY: 15,
                                        show: true,
                                        color: "#888",
                                        fontSize: "12px",
                                        fontFamily: 'CPF_Imm_Sook'
                                      },
                                      value: {
                                        offsetY: -10,
                                        color: "#111",
                                        fontSize: "18px",
                                        fontFamily: 'CPF_Imm_Sook',
                                        show: true
                                      }
                                    }
                                  }
                                },
                                labels: ['Target 92%'],
                                colors: [getColorAVA()],
                                fill: {
                                  type: "gradient",
                                  gradient: {
                                    shade: "dark",
                                    type: "vertical",
                                    gradientToColors: [getColorAVA()],
                                    stops: [0, 100]
                                  }
                                },
                              }}
                              series={[0]}
                              type="radialBar"
                              width="80%"
                              height="80%"
                            />
                            :
                            <Chart
                              options={{
                                chart: {
                                  type: 'radialBar',
                                  offsetY: -25,
                                  offsetX: -40,
                                  parentHeightOffset: 0,
                                  toolbar: {
                                    show: false
                                  }
                                },
                                plotOptions: {
                                  radialBar: {
                                    hollow: {
                                      margin: 0,
                                      size: "60%",
                                    },

                                    dataLabels: {
                                      showOn: "always",
                                      name: {
                                        offsetY: 15,
                                        show: true,
                                        color: "#888",
                                        fontSize: "12px",
                                        fontFamily: 'CPF_Imm_Sook'
                                      },
                                      value: {
                                        offsetY: -10,
                                        color: "#111",
                                        fontSize: "18px",
                                        fontFamily: 'CPF_Imm_Sook',
                                        show: true
                                      }
                                    }
                                  }
                                },
                                labels: ['Target 92%'],
                                colors: [getColorAVA()],
                                fill: {
                                  type: "gradient",
                                  gradient: {
                                    shade: "dark",
                                    type: "vertical",
                                    gradientToColors: [getColorAVA()],
                                    stops: [0, 100]
                                  }
                                },
                              }}
                              series={[Math.round(oee.A != 'NA' ? oee.A : 0)]}
                              type="radialBar"
                              width="80%"
                              height="80%"
                            />
                          }
                        </Box>
                      </Grid>
                      {/* P */}
                      <Grid item >
                        <Typography color="black" fontSize={'13px'} fontWeight="bold" align='left' style={{ position: "relative", top: "-70px", left: "-40px" }}>
                          PER
                        </Typography>
                        <Box sx={{ display: 'block', position: "relative", top: "-70px" }}>
                          {(loading === true || oee.P === 'No data')
                            ? <Chart
                              options={{
                                chart: {
                                  type: 'radialBar',
                                  offsetY: -25,
                                  offsetX: -40,
                                  parentHeightOffset: 0,
                                  toolbar: {
                                    show: false
                                  }
                                },
                                plotOptions: {
                                  radialBar: {
                                    hollow: {
                                      margin: 0,
                                      size: "60%",
                                    },

                                    dataLabels: {
                                      showOn: "always",
                                      name: {
                                        offsetY: 15,
                                        show: true,
                                        color: "#888",
                                        fontSize: "12px",
                                        fontFamily: 'CPF_Imm_Sook'
                                      },
                                      value: {
                                        offsetY: -10,
                                        color: "#111",
                                        fontSize: "18px",
                                        fontFamily: 'CPF_Imm_Sook',
                                        show: true
                                      }
                                    }
                                  }
                                },
                                labels: ['Target 95%'],
                                colors: [getColorPER()],
                                fill: {
                                  type: "gradient",
                                  gradient: {
                                    shade: "dark",
                                    type: "vertical",
                                    gradientToColors: [getColorPER()],
                                    stops: [0, 100]
                                  }
                                },
                              }}
                              series={[0]}
                              type="radialBar"
                              width="80%"
                              height="80%"
                            />
                            :
                            <Chart
                              options={{
                                chart: {
                                  type: 'radialBar',
                                  offsetY: -25,
                                  offsetX: -40,
                                  parentHeightOffset: 0,
                                  toolbar: {
                                    show: false
                                  }
                                },
                                plotOptions: {
                                  radialBar: {
                                    hollow: {
                                      margin: 0,
                                      size: "60%",
                                    },

                                    dataLabels: {
                                      showOn: "always",
                                      name: {
                                        offsetY: 15,
                                        show: true,
                                        color: "#888",
                                        fontSize: "12px",
                                        fontFamily: 'CPF_Imm_Sook'
                                      },
                                      value: {
                                        offsetY: -10,
                                        color: "#111",
                                        fontSize: "18px",
                                        fontFamily: 'CPF_Imm_Sook',
                                        show: true
                                      }
                                    }
                                  }
                                },
                                labels: ['Target 95%'],
                                colors: [getColorPER()],
                                fill: {
                                  type: "gradient",
                                  gradient: {
                                    shade: "dark",
                                    type: "vertical",
                                    gradientToColors: [getColorPER()],
                                    stops: [0, 100]
                                  }
                                },
                              }}
                              series={[Math.round(oee.P != 'NA' ? oee.P : 0)]}
                              type="radialBar"
                              width="80%"
                              height="80%"
                            />
                          }
                        </Box>
                      </Grid>
                      {/* Q */}
                      <Grid item >
                        <Typography color="black" fontSize={'13px'} fontWeight="bold" align='left' style={{ position: "relative", top: "-145px", left: "-40px" }}>
                          QUA
                        </Typography>
                        <Box sx={{ display: 'block', position: "relative", top: "-140px" }}>
                          {(loading === true || oee.Q === 'No data')
                            ? <Chart
                              options={{
                                chart: {
                                  type: 'radialBar',
                                  offsetY: -25,
                                  offsetX: -40,
                                  parentHeightOffset: 0,
                                  toolbar: {
                                    show: false
                                  }
                                },
                                plotOptions: {
                                  radialBar: {
                                    hollow: {
                                      margin: 0,
                                      size: "60%",
                                    },

                                    dataLabels: {
                                      showOn: "always",
                                      name: {
                                        offsetY: 15,
                                        show: true,
                                        color: "#888",
                                        fontSize: "12px",
                                        fontFamily: 'CPF_Imm_Sook'
                                      },
                                      value: {
                                        offsetY: -10,
                                        color: "#111",
                                        fontSize: "18px",
                                        fontFamily: 'CPF_Imm_Sook',
                                        show: true
                                      }
                                    }
                                  }
                                },
                                labels: ['Target 98%'],
                                colors: [getColorQUA],
                                fill: {
                                  type: "gradient",
                                  gradient: {
                                    shade: "dark",
                                    type: "vertical",
                                    gradientToColors: [getColorQUA()],
                                    stops: [0, 100]
                                  }
                                },
                              }}
                              series={[0]}
                              type="radialBar"
                              width="80%"
                              height="80%"
                            />
                            :
                            <Chart
                              options={{
                                chart: {
                                  type: 'radialBar',
                                  offsetY: -25,
                                  offsetX: -40,
                                  parentHeightOffset: 0,
                                  toolbar: {
                                    show: false
                                  }
                                },
                                plotOptions: {
                                  radialBar: {
                                    hollow: {
                                      margin: 0,
                                      size: "60%",
                                    },

                                    dataLabels: {
                                      showOn: "always",
                                      name: {
                                        offsetY: 15,
                                        show: true,
                                        color: "#888",
                                        fontSize: "12px",
                                        fontFamily: 'CPF_Imm_Sook'
                                      },
                                      value: {
                                        offsetY: -10,
                                        color: "#111",
                                        fontSize: "18px",
                                        fontFamily: 'CPF_Imm_Sook',
                                        show: true
                                      }
                                    }
                                  }
                                },
                                labels: ['Target 98%'],
                                colors: [getColorQUA],
                                fill: {
                                  type: "gradient",
                                  gradient: {
                                    shade: "dark",
                                    type: "vertical",
                                    gradientToColors: [getColorQUA()],
                                    stops: [0, 100]
                                  }
                                },
                              }}
                              series={[Math.round(oee.Q != 'NA' ? oee.Q : 0)]}
                              type="radialBar"
                              width="80%"
                              height="80%"
                            />
                          }
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* SKU */}
                  <Grid item xs={2} lg={2} xl={2}>
                    <Box width="100%" style={{ position: "relative", top: "-1px", left: "10px" }}>
                      <Typography color="#17b341" fontSize="20px" fontWeight="bold" align='center'>
                        Skus
                      </Typography>
                    </Box>
                    <table style={{ borderLeft: "2px solid orange", borderRight: "2px solid orange", 'padding-top': '10px' }}>
                      <tbody>
                        {[
                          { name: "Target", value: <Button color="success" style={{ fontSize: '18px' }}>{skuOverview.TARGET}</Button> },
                          { name: "Completed", value: <Button variant="contained" color="success" style={{ fontSize: '18px' }}>{skuOverview.COMPLETED}</Button> },
                          { name: "On process", value: <Button variant="outlined" color="success" style={{ color: "black", fontSize: '18px' }}>{skuOverview.RUNNING}</Button> },
                          { name: "Waiting", value: <Button color="success" style={{ color: "black", fontSize: '18px' }}>{skuOverview.WAITING}</Button> }
                        ].map((row) => (
                          <tr key={row.name}>
                            <td style={{ width: '30%', fontSize: 18, fontWeight: 'bold' }} align="right">
                              {row.name}
                            </td>
                            <td style={{ width: '23%', fontSize: 18 }} align="right">
                              {row.value}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </Grid>
                  <Grid item xs={6} lg={6} xl={6.5}>
                    <Grid item xs={12} lg={12} xl={12}>
                      <Box pt={1} px={2} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative", top: "-10px" }}>
                        <table style={{ 'padding-top': '0px' }}>
                          <tbody>
                            <tr>
                              <td align="left" style={{ width: '80px', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}></td>
                              <td align="left" style={{ width: '80px', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>Target</td>
                              <td align="left" style={{ width: '80px', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>Actual</td>
                              <td align="left" style={{ width: '120px', fontSize: 16, borderBottom: 'none', height: "1px" }}></td>
                              <td align="center" style={{ width: '80px', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>Remaining</td>
                            </tr>
                            <tr>
                              <td align="left" style={{ width: '80px', fontSize: 20, fontWeight: 'bold', borderBottom: 'none' }}>Total</td>
                              <td align="left" style={{ width: '80px', fontSize: 16, borderBottom: 'none' }}>{totalPerformance.TARGET_PRODUCTION_QUANTITY} Ton</td>
                              <td align="left" style={{ width: '80px', fontSize: 16, borderBottom: 'none' }}>{totalPerformance.ACTUAL_WEIGHT} Ton</td>
                              <td align="left" style={{ width: '300px', fontSize: 16, borderBottom: 'none' }}>
                                {no_data === 1 || totalPerformance !== undefined ?
                                  <BarChartAccumulativeProduction
                                    target_quantity={totalPerformance.TARGET_PRODUCTION_QUANTITY}
                                    actual_weight={totalPerformance.ACTUAL_WEIGHT}
                                    unit={totalPerformance.PRODUCTION_UNIT}
                                    target_now={Math.round(overallTargetNow)}
                                    height='50%'
                                  />
                                  : ''}
                              </td>
                              <td align="center" style={{ width: '80px', fontSize: 16, borderBottom: 'none' }}>{(Math.round((totalPerformance.TARGET_PRODUCTION_QUANTITY - totalPerformance.ACTUAL_WEIGHT) * 100) / 100).toFixed(2)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Box>
                    </Grid>

                    <Grid item xs={12} lg={12} xl={12}>
                      <Box width="100%" style={{ position: "relative", top: "0px" }}>
                        <table>
                          <tbody>
                            <tr>
                              <td align="center" style={{ width: '15%', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>Line</td>
                              <td align="center" style={{ width: '15%', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>SKUs</td>
                              <td align="center" style={{ width: '15%', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>Target</td>
                              <td align="center" style={{ width: '15%', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>Unit</td>
                              <td align="center" style={{ width: '40%', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>Chart</td>
                              <td align="center" style={{ width: '15%', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>Remaining</td>
                            </tr>
                            {
                              loading ? null :
                                responseObject.SKU_DETAIL.map(index => (
                                  <tr>
                                    <td align="center" style={{ width: '15%', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>
                                      {/* pass data from a page to another page (https://stackoverflow.com/questions/59701050/how-to-pass-data-from-a-page-to-another-page-using-react-router) */}
                                      <Link
                                        to="/productionLine"
                                        state={{ SKU: index.WORK_CENTER }}// your data array of objects
                                        variant="button"
                                        color="black"
                                        fontWeight="medium"
                                        style={{ textDecoration: 'none' }}
                                      >
                                        {index.WORK_CENTER}
                                      </Link>
                                    </td>
                                    <td align="center" style={{ width: '15%', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>{index.SKUs}</td>
                                    <td align="center" style={{ width: '15%', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>{index.TARGET}</td>
                                    <td align="center" style={{ width: '15%', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>{index.PRODUCTION_UNIT}</td>
                                    <td align="center" style={{ width: '40%', fontSize: 16, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>

                                      { no_data === 1 || responseObject !== undefined ?
                                        <BarChartAccumulativeSKUProduction
                                        data={getValueByKey(barSeries, index.WORK_CENTER)}
                                        unit={'TON'}
                                        height='42%'
                                        target_now={getValueByKey(targetNowSeries, index.WORK_CENTER)}
                                      />
                                    : ''
                                    }
                                    </td>
                                    <td align="center" style={{ width: '15%', fontSize: 14, fontWeight: 'bold', borderBottom: 'none', height: "1px" }}>
                                      <RemainingProductionPage2 data={getValueByKey(barSeries, index.WORK_CENTER)} />
                                    </td>
                                  </tr>
                                ))
                            }
                          </tbody>
                        </table>
                      </Box>
                    </Grid>
                  </Grid>

                </Grid>
              </Box>
            </Item>
          </Grid>
        </Grid>

        {/* Bottom row */}
        <Grid id="Bottom-row" container spacing={1} style={{ position: "relative", top: "-340px" }}>
          <Grid xs={12}>
            <Item sx={() => ({
              width: '98%',
              height: '400px',
              py: "22px",
              position: 'absolute',
              backgroundImage: `url(${Plant})`,
              backgroundSize: "cover",
              backgroundPosition: "center"
            })}>

              <Button variant="contained" style={{ top: "100px", left: "298px", fontSize: '12px', width: '60px', height: '18px', background: "#3cbea8" }}>Multihead1</Button>
              <Button variant="contained" style={{ top: "72px", left: "268px", fontSize: '12px', width: '60px', height: '18px', background: "#3cbea8" }}>Multihead2</Button>
              <Button variant="contained" style={{ top: "48px", left: "235px", fontSize: '12px', width: '60px', height: '18px', background: "#3cbea8" }}>Multihead3</Button>
              <Button variant="contained" style={{ top: "18px", left: "210px", fontSize: '12px', width: '60px', height: '18px', background: "#3cbea8" }}>Multihead4</Button>
              <div >
                <LensIcon fontSize="small" sx={{ color: color4 }} style={{ position: 'absolute', left: '390px', top: '40px' }}>Multihead4</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color3 }} style={{ position: 'absolute', left: '350px', top: '70px' }}>Multihead3</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color2 }} style={{ position: 'absolute', left: '320px', top: '95px' }}>Multihead2</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color1 }} style={{ position: 'absolute', left: '285px', top: '120px' }}>Multihead1</LensIcon>
              </div>


              <Button variant="contained" style={{ top: "0px", left: "562px", fontSize: '12px', width: '40px', height: '18px', background: "#3cbea8" }}>IQF10</Button>
              <Button variant="contained" style={{ top: "30px", left: "463px", fontSize: '12px', width: '40px', height: '18px', background: "#3cbea8" }}>IQF9</Button>
              <Button variant="contained" style={{ top: "60px", left: "363px", fontSize: '12px', width: '40px', height: '18px', background: "#3cbea8" }}>IQF8</Button>
              <Button variant="contained" style={{ top: "88px", left: "270px", fontSize: '12px', width: '40px', height: '18px', background: "#3cbea8" }}>IQF7</Button>
              <div>
                <LensIcon fontSize="small" sx={{ color: color7 }} style={{ position: 'absolute', left: '450px', top: '130px' }}>IQF7</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color8 }} style={{ position: 'absolute', left: '480px', top: '100px' }}>IQF8</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color9 }} style={{ position: 'absolute', left: '515px', top: '72px' }}>IQF9</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color10 }} style={{ position: 'absolute', left: '550px', top: '42px' }}>IQF10</LensIcon>
              </div>


              {/* <Tooltip 
                title={
                  <Box style={{ position: "relative", top: "30px", left: "10px", width: "500px", height: "80px" }} >
                    <BarChartStatusMachine
                       target_quantity={totalPerformance.TARGET_PRODUCTION_QUANTITY}
                       actual_weight={totalPerformance.ACTUAL_WEIGHT}
                       unit={totalPerformance.PRODUCTION_UNIT}
                       target_now = {overallTargetNow}
                       height='50%'

                    />
        
                  </Box>
                } 
                placement="top" 
                > */}
              {/* EFRB5106 */}
              <Tooltip
                placement='top'
                title={runtime == 1 ? <BarChartStatusMachine height='50%' data={fryer3}></BarChartStatusMachine> : "Loading"}
              >
                <Button variant="contained" style={{ top: "175px", left: "852px", fontSize: '12px', width: '90px', height: '18px', background: "#3cbea8" }}>Fryer(EFRB5106)</Button>
              </Tooltip>
              {/* <Button variant="contained" style={{top: "175px", left: "852px", fontSize: '10px', fontFamily: 'Monospace', width: '90px', height: '18px', background: "#3cbea8"}}>Fryer(EFRB5106)</Button> */}
              {/* </Tooltip> */}
              {/* EFRB5108 */}
              <Tooltip
                title={runtime == 1 ? <BarChartStatusMachine height='50%' data={fryer1}></BarChartStatusMachine> : "Loading"}
              >
                <Button variant="contained" style={{ top: "210px", left: "732px", fontSize: '12px', width: '90px', height: '18px', background: "#3cbea8" }}>Fryer(EFRB5108)</Button>
              </Tooltip>
              {/* EFRB5107 */}
              <Tooltip
                title={runtime == 1 ? <BarChartStatusMachine height='50%' data={fryer2}></BarChartStatusMachine> : "Loading"}
              >
                <Button variant="contained" style={{ top: "180px", left: "500px", fontSize: '12px', width: '90px', height: '18px', background: "#3cbea8" }}>Fryer(EFRB5107)</Button>
              </Tooltip>
              <div>
                <LensIcon fontSize="small" sx={{ color: color5108 }} style={{ position: 'absolute', left: '810px', top: '275px' }}>Fryer(EFRB5108)</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color5106 }} style={{ position: 'absolute', left: '840px', top: '240px' }}>Fryer(EFRB5106)</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color5107 }} style={{ position: 'absolute', left: '665px', top: '240px' }}>Fryer(EFRB5107)</LensIcon>
              </div>


              <Button variant="contained" style={{ top: "140px", left: "702px", fontSize: '12px', width: '70px', height: '18px', background: "#3cbea8" }}>Superheat1</Button>
              <Button variant="contained" style={{ top: "122px", left: "650px", fontSize: '12px', width: '70px', height: '18px', background: "#3cbea8" }}>Superheat2</Button>
              <div>
                <LensIcon fontSize="small" sx={{ color: colorSuperheat1 }} style={{ position: 'absolute', left: '690px', top: '218px' }}>Superheat1</LensIcon>
                <LensIcon fontSize="small" sx={{ color: colorSuperheat2 }} style={{ position: 'absolute', left: '710px', top: '200px' }}>Superheat2</LensIcon>
              </div>


              <Button variant="contained" style={{ top: "74px", left: "745px", fontSize: '12px', width: '125px', height: '18px', background: "#3cbea8" }}>Hot Air(CKT5024)</Button>
              <Button variant="contained" style={{ top: "55px", left: "635px", fontSize: '12px', width: '125px', height: '18px', background: "#3cbea8" }}>Hot Air(FLC5048)</Button>
              <Button variant="contained" style={{ top: "35px", left: "525px", fontSize: '12px', width: '125px', height: '18px', background: "#3cbea8" }}>Hot Air(CKT5047)</Button>
              <div>
                <LensIcon fontSize="small" sx={{ color: color5024 }} style={{ position: 'absolute', left: '732px', top: '175px' }}>Hot Air(CKT5024)</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color5048 }} style={{ position: 'absolute', left: '748px', top: '157px' }}>Hot Air(FLC5048)</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color5047 }} style={{ position: 'absolute', left: '765px', top: '137px' }}>Hot Air(FLC5047)</LensIcon>
              </div>


              <Button variant="contained" style={{ top: "176px", left: "1317px", fontSize: '12px', width: '70px', height: '18px', background: "#3cbea8" }}>Tumbler1</Button>
              <Button variant="contained" style={{ top: "195px", left: "1231px", fontSize: '12px', width: '70px', height: '18px', background: "#3cbea8" }}>Tumbler2</Button>
              <Button variant="contained" style={{ top: "210px", left: "1143px", fontSize: '12px', width: '70px', height: '18px', background: "#3cbea8" }}>Tumbler3</Button>
              <Button variant="contained" style={{ top: "225px", left: "1055px", fontSize: '12px', width: '70px', height: '18px', background: "#3cbea8" }}>Tumbler4</Button>
              <Button variant="contained" style={{ top: "240px", left: "965px", fontSize: '12px', width: '70px', height: '18px', background: "#3cbea8" }}>Tumbler5</Button>
              <Button variant="contained" style={{ top: "255px", left: "875px", fontSize: '12px', width: '70px', height: '18px', background: "#3cbea8" }}>Tumbler6</Button>
              <div>
                <LensIcon fontSize="small" sx={{ color: color5088 }} style={{ position: 'absolute', left: '1218px', top: '365px' }}>SMD5088</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color5087 }} style={{ position: 'absolute', left: '1236px', top: '352px' }}>SMD5087</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color5086 }} style={{ position: 'absolute', left: '1255px', top: '340px' }}>SMD5086</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color5085 }} style={{ position: 'absolute', left: '1272px', top: '330px' }}>SMD5085</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color5084 }} style={{ position: 'absolute', left: '1290px', top: '318px' }}>SMD5084</LensIcon>
                <LensIcon fontSize="small" sx={{ color: color5083 }} style={{ position: 'absolute', left: '1305px', top: '305px' }}>SMD5083</LensIcon>
              </div>


            </Item>
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}

export default Production;