import { useRef, useEffect, useState } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { apiUrl } from '../../../constants'
import moment from 'moment-timezone';
import axios from 'axios'
import db from "firebase-config"
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "theme";
import ColumnChartGiveaway from "components/chart/ColumnChartGiveaway";
import { useNavigate } from 'react-router-dom';
import PopupGiveawayLine8 from "components/model/PopupGiveawayLine8";



const GiveawayLine8P2 = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [chart_giveaway, setChart_giveaway] = useState([]);
    const [no_data, setNo_data] = useState(false);
    const [no_data_p2, setNo_data_p2] = useState(false);
    const [giveawayP1, setGiveawayP1] = useState({
        WARNING: "",
        PACK_SIZE_STD: "",
        SETTING_PACK_SIZE: ""
    });
    const [giveawayP2, setGiveawayP2] = useState({
        ACTUAL_PERCENT_GIVEAWAY: "",
        TARGET_GIVEAWAY: "",
        MIN_GIVEAWAY: ""
    });
    const [keeplive, setkeeplive] = useState([]);

    setTimeout(() => {
        navigate('/giveaway/line8/p1');
    }, 30000);


    useEffect(() => {
        const subscriberPerformance = db
            .collection("meskr_giveaway_5_min_timeframe")
            .onSnapshot((querySnapshot) => {
                const performanceData = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                const filteredDocs = performanceData.filter(doc =>
                    doc.id.includes("line8")
                );
                setChart_giveaway(filteredDocs);
                function groupBy(filteredDocs, property) {
                    return filteredDocs.reduce(function (memo, x) {
                        if (!memo[x[property]]) {
                            memo[x[property]] = [];
                        }
                        memo[x[property]].push(x);
                        return memo;
                    }, {});
                }

                //group by name
                const grouped = groupBy(filteredDocs, "TIMESTAMP");
                const keys = Object.keys(grouped);
                var output = [];

                //loop keys
                keys.forEach(key => {
                    //merge using reduce
                    const out = grouped[key].reduce((current) => {
                        return {
                            GIVEAWAY_PERCENT: current.GIVEAWAY_PERCENT,
                            TIMESTAMP: current.TIMESTAMP,

                        }
                    });
                    output.push(out);
                });

                sessionStorage.setItem('time_giveaway_line8', output.map(item =>
                    moment(new Date(item.TIMESTAMP)).tz('Asia/Bangkok').format('HH:mm')
                ));
                sessionStorage.setItem('value_giveaway_line8', output.map(i => i.GIVEAWAY_PERCENT));

            });

        const subscriberError = db
            .collection("meskr_keeplive")
            .onSnapshot((querySnapshot) => {
                const errorData = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setkeeplive(errorData);
                let mul1 = errorData.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line8.0NoError"));
                sessionStorage.setItem('multihead_line8', mul1?.VALUE);
                let c_w = errorData.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line8.Status"));
                sessionStorage.setItem('c_w_line8', c_w?.VALUE);
                let x_ray = errorData.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line8.Status"));
                sessionStorage.setItem('x_ray_line8', x_ray?.VALUE);
                let m_d = errorData.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line8.Status"));
                sessionStorage.setItem('m_d_line8', m_d?.VALUE);
            });

        // Cleanup listeners
        return () => {
            subscriberPerformance();
            subscriberError();
        };
    }, []);


    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.GIVEAWAY_LINE8_PAGE1}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        setGiveawayP1({
                            WARNING: response.data[0].WARNING,
                            SETTING_PACK_SIZE: response.data[0].SETTING_PACK_SIZE,
                            PACK_SIZE_STD: response.data[0].PACK_SIZE_STD,
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }



    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl.GIVEAWAY_LINE8_PAGE2}`, {
                    headers: {
                        "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)"
                    }
                });

                if (response.status === 200 && response.data && response.data[0]) {
                    const data = response.data[0];
                    setGiveawayP2({
                        ACTUAL_PERCENT_GIVEAWAY: data.ACTUAL_PERCENT_GIVEAWAY || 0,
                        TARGET_GIVEAWAY: data.TARGET_GIVEAWAY || 0,
                        MIN_GIVEAWAY: data.MIN_GIVEAWAY || 0
                    });
                    sessionStorage.setItem('actual_percent_giveaway_line8_p2', data.ACTUAL_PERCENT_GIVEAWAY);
                    sessionStorage.setItem('target_giveaway_line8_p2', data.TARGET_GIVEAWAY);
                    sessionStorage.setItem('min_giveaway_line8_p2', data.MIN_GIVEAWAY);
                    setNo_data_p2(1);
                } else {
                    // Handle error or incomplete data
                    console.error('Received invalid data:', response.data);
                    setNo_data_p2(2);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setNo_data_p2(2);
            }
        };

        fetchData();
    }, []);

    const multihead1 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line8.0NoError"));
    const check_weihgt = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line8.Status"));
    const xray = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line8.Status"));
    const md = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line8.Status"));


    function groupBy(chart_giveaway, property) {
        return chart_giveaway.reduce(function (memo, x) {
            if (!memo[x[property]]) {
                memo[x[property]] = [];
            }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    //group by name
    const grouped = groupBy(chart_giveaway, "TIMESTAMP");
    const keys = Object.keys(grouped);
    var output = [];

    //loop keys
    keys.forEach(key => {
        //merge using reduce
        const out = grouped[key].reduce((current) => {
            return {
                GIVEAWAY_PERCENT: current.GIVEAWAY_PERCENT,
                TIMESTAMP: current.TIMESTAMP,

            }
        });
        output.push(out);
    });


    const time_giveaway = output.map(item =>
        moment(new Date(item.TIMESTAMP)).tz('Asia/Bangkok').format('HH:mm')
    )

    const value_giveaway = output.map(i => i.GIVEAWAY_PERCENT == null ? 0 : i.GIVEAWAY_PERCENT);

    const show_value = value_giveaway == 0 ? [0] : value_giveaway;

    const show_time = time_giveaway == 0 ? [0] : time_giveaway;

    const sliced_show_value = show_value.slice(-12);
    const sliced_show_time = show_time.slice(-12);



    return (
        <>
            {giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' || sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' || multihead1?.VALUE == false || check_weihgt?.VALUE == 0 || xray?.VALUE == 0 || md?.VALUE == 0 || (sessionStorage.getItem('multihead_line8') == 'true') == false || Number(sessionStorage.getItem('c_w_line8')) == 0 || Number(sessionStorage.getItem('x_ray_line8')) == 0 || Number(sessionStorage.getItem('m_d_line8')) == 0 ? <PopupGiveawayLine8 /> :
                <Helmet>
                    <title>Giveaway Line8 Page2 | MES</title>
                </Helmet>
            }
            <Grid item xs={12} sm={12} style={{ backgroundColor: colors.while[100], paddingTop: 0, paddingLeft: 20 }}>
                <Grid item xs={12} textAlign="left" style={{ paddingRight: 0 }}>
                    <Typography style={{ color: colors.black[100], fontSize: 48, fontWeight: 'bold' }} >
                        Realtime Giveaway (%)
                    </Typography>
                </Grid>
                <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                        <Grid item xs={8} >
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" style={{ position: "relative", height: '70vh' }}>
                                <ColumnChartGiveaway
                                    value={sliced_show_value}
                                    time={sliced_show_time}
                                    setting={no_data == 1 ? Number(giveawayP1.SETTING_PACK_SIZE) : 707}
                                    std={no_data == 1 ? Number(giveawayP1.PACK_SIZE_STD) : 700}
                                    target={no_data_p2 == 1 ? (giveawayP2.TARGET_GIVEAWAY == undefined ? 0 : (giveawayP2.TARGET_GIVEAWAY == 'None' ? 0 : giveawayP2.TARGET_GIVEAWAY)) : 0}
                                />

                            </Grid>
                        </Grid>

                        <Grid item xs={4} style={{ paddingTop: 0 }}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold' }} >
                                    Best Practice
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: '#4AC6B1', fontSize: 32, fontWeight: 'bold', lineHeight: "4vh" }} >
                                    {no_data_p2 == 1 ? (giveawayP2.MIN_GIVEAWAY == undefined ? '-' : (giveawayP2.MIN_GIVEAWAY == 'None' ? '-' : (Math.round(Number(giveawayP2.MIN_GIVEAWAY) * 100) / 100).toFixed(2))) : sessionStorage.getItem('min_giveaway_line8_p2') == null ? '-' : (sessionStorage.getItem('min_giveaway_line8_p2') == 'undefined' ? '-' : (sessionStorage.getItem('min_giveaway_line8_p2') == 'None' ? '-' : (Math.round(Number(sessionStorage.getItem('min_giveaway_line8_p2')) * 100) / 100).toFixed(2)))}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 25 }}>
                                <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold' }} >
                                    Target
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: '#4AC6B1', fontSize: 32, fontWeight: 'bold', lineHeight: "4vh" }} >
                                    {no_data_p2 == 1 ? (giveawayP2.TARGET_GIVEAWAY == undefined ? '-' : (giveawayP2.TARGET_GIVEAWAY == 'None' ? '-' : Math.round(Number(giveawayP2.TARGET_GIVEAWAY)))) : sessionStorage.getItem('target_giveaway_line8_p2') == null ? '-' : (sessionStorage.getItem('target_giveaway_line8_p2') == 'undefined' ? '-' : (sessionStorage.getItem('target_giveaway_line8_p2') == 'None' ? '-' : Math.round(Number(sessionStorage.getItem('target_giveaway_line8_p2')))))}%
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 30 }} >
                                <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold' }} >
                                    Actual
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: '#4AC6B1', fontSize: 32, fontWeight: 'bold', lineHeight: "4vh" }} >
                                    {no_data_p2 == 1 ? (giveawayP2.ACTUAL_PERCENT_GIVEAWAY == undefined ? '-' : (giveawayP2.ACTUAL_PERCENT_GIVEAWAY == 'None' ? '-' : (Math.round(Number(giveawayP2.ACTUAL_PERCENT_GIVEAWAY) * 100) / 100).toFixed(2))) : sessionStorage.getItem('actual_percent_giveaway_line8_p2') == null ? '-' : (sessionStorage.getItem('actual_percent_giveaway_line8_p2') == 'undefined' ? '-' : (sessionStorage.getItem('actual_percent_giveaway_line8_p2') == 'None' ? '-' : (Math.round(Number(sessionStorage.getItem('actual_percent_giveaway_line8_p2')) * 100) / 100).toFixed(2)))}%
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default GiveawayLine8P2
