import React, { useEffect, useState, useMemo } from "react";
import { Box, Grid, Card, Container, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import '../styles.css'
import axios from 'axios';
import moment from 'moment-timezone';
import { apiUrl } from "../constants";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Slide
} from "@material-ui/core/";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ButtonGroup from '@mui/material/ButtonGroup';
import Circular from '../components/model/Circular';





var token_url = apiUrl.TOKEN;

const slideProps = {
    mountOnEnter: true,
    unmountOnExit: true,
    timeout: { enter: "auto", exit: "auto" }
};

export default function Reject() {
    const today = new Date();
    const hourago = new Date(today.getTime());
    const hourago_start = new Date(today.getTime() - (24 * 60 * 60 * 1000));
    const datetime = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');
    const date_start = moment(hourago_start).tz('Asia/Bangkok').format('YYYY-MM-DD');
    const date_end = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');


    const [reject_preparation_zone, setReject_preparation_zone] = useState([]);
    const [done, setDone] = useState(false);
    const [popupTimeout, setPopupTimeout] = useState(false);
    const [openPopupTimeout, setOpenPopupTimeout] = useState(false);

    const handleClosePopupTimeout = () => {
        setOpenPopupTimeout(false);
    };


    const checkToken = async () => {
        let access_exp = localStorage.getItem("rb_ac_exp");
        let refresh_exp = localStorage.getItem("rb_rf_exp");
        //console.log('access_exp: ' + access_exp);
        //console.log('refresh_exp: ' + refresh_exp);
        if (refresh_exp < Date.now() / 1000) {
            //console.log("refresh is expiried");
            // alert('Please log in again')
            localStorage.clear();
            window.location = "/Login";
        } else if (access_exp < Date.now() / 1000) {
            const refresh = localStorage.getItem("rb_rf_token");

            let data = {
                refresh: refresh,
            };
            let result = await axios.post(token_url, data);
            //console.log("got new access_token!");
            localStorage.setItem("rb_ac_token", result.data.access);
        }
    };



    // useEffect(() => {
    //     async function fetchMyAPI() {
    //         // check access token expiry
    //         checkToken();
    //         setDone(true);
    //         //console.log(`${apiUrl.BETWEEN_PREPARATION_ZONE}${date_start}/${date_end}/`)
    //         var get_datakorat = `${apiUrl.BETWEEN_PREPARATION_ZONE}${date_start}/${date_end}/` // date format must be YYYY-MM-DD, eg. 2022-06-16
    //         await axios.get(get_datakorat, {
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
    //                 "X-xss-protection": "1; mode=block;",
    //                 "X-frame-options": "SAMEORIGIN",
    //                 "X-content-type-options": "nosniff",
    //                 "Cache-Control": "no-cache, private",
    //                 "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
    //                 "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
    //             }
    //         }
    //         )
    //             .then(response => {
    //                 //console.log(response.data)
    //                 if (response.status == 200) {
    //                     setReject_preparation_zone(response.data.filter(i => i.STATUS === "Reject"))
    //                     setDone(false);
    //                 }


    //             })
    //             .catch(error => {
    //                 console.log('error: ', error)
    //                 setDone(false);
    //             })
    //     }
    //     fetchMyAPI()

    //     // const interval = setInterval(() => fetchMyAPI(), 1000)
    //     // return () => {
    //     //     clearInterval(interval);
    //     // }

    // }, []);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             checkToken();
    //             setDone(true);
    //             const response = await axios.get(`${apiUrl.BETWEEN_PREPARATION_ZONE}${date_start}/${date_end}/`, {
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
    //                     "X-xss-protection": "1; mode=block;",
    //                     "X-frame-options": "SAMEORIGIN",
    //                     "X-content-type-options": "nosniff",
    //                     "Cache-Control": "no-cache, private",
    //                     "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
    //                     "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
    //                 }
    //             });
    //             if (response.status === 200) {
    //                 setReject_preparation_zone(response.data.filter(i => i.STATUS === "Reject"))
    //                 setDone(false);
    //             }
    //         } catch (error) {
    //             console.error("Error fetching data: ", error);
    //             setDone(false);
    //         } 
    //     };

    //     fetchData();
    // }, [date_start, date_end]);

    useEffect(() => {
        const fetchData = async () => {
            const timeout = 10000; // กำหนดเวลา timeout 10 วินาที (10000 มิลลิวินาที)

            // สร้าง Promise ที่จะ reject เมื่อ timeout
            const timeoutError = new Promise((_, reject) =>
                setTimeout(() => reject(new Error("Request timed out")), timeout)
            );

            try {
                checkToken();
                setDone(true);

                // ใช้ Promise.race เพื่อตรวจสอบ response หรือ timeout ว่าจะเกิดขึ้นก่อน
                const response = await Promise.race([
                    axios.get(`${apiUrl.BETWEEN_PREPARATION_ZONE}${date_start}/${date_end}/`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("rb_ac_token")}`,
                            "X-xss-protection": "1; mode=block;",
                            "X-frame-options": "SAMEORIGIN",
                            "X-content-type-options": "nosniff",
                            "Cache-Control": "no-cache, private",
                            "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                            "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                        }
                    }),
                    timeoutError
                ]);

                if (response.status === 200) {
                    setReject_preparation_zone(response.data.filter(i => i.STATUS === "Reject"));
                    setDone(false);
                    setPopupTimeout(1)
                }
            } catch (error) {
                if (error.message === "Request timed out") {
                    console.error("Timeout Error: The request took too long to complete.");
                } else {
                    console.error("Error fetching data: ", error);
                }
                setDone(false);
                setPopupTimeout(2)
            }
        };

        fetchData();
    }, [date_start, date_end]);


    const sort_factory_date = reject_preparation_zone.sort((a, b) => a.FACTORY_DATE.localeCompare(b.FACTORY_DATE));

    const sort_batch = sort_factory_date.sort((a, b) => a.AT_REJECTED_TIMESTAMP.localeCompare(b.AT_REJECTED_TIMESTAMP));



    // const categories = {};
    // for (const item of reject_preparation_zone) {
    //     if (item.MATERIAL_DESCRIPTION in categories) {
    //         categories[item.MATERIAL_DESCRIPTION].push(item);
    //     } else {
    //         categories[item.MATERIAL_DESCRIPTION] = [item];
    //     }
    // }

    const categories = useMemo(() => {
        const categories = {};
        reject_preparation_zone.forEach((item) => {
            if (!categories[item.MATERIAL_DESCRIPTION]) {
                categories[item.MATERIAL_DESCRIPTION] = [];
            }
            categories[item.MATERIAL_DESCRIPTION].push(item);
        });
        return categories;
    }, [reject_preparation_zone]);

    useEffect(() => {
        if (popupTimeout === 2) {
            setOpenPopupTimeout(true);
        }
    }, [popupTimeout]);

    //console.log(data.length)


    return (

        <div className="App">
            {done ? <Circular /> :
                <Grid container spacing={1} style={{ paddingLeft: 0, paddingTop: 0, paddingRight: 0 }} >
                    <Grid item xs={12} md={12} lg={12} xl={12} textAlign="left" style={{ position: "relative", top: '0vw', height: '0px' }}>

                        <Box >
                            <div>
                                {Object.entries(categories).map((entry) => {
                                    const category = entry[0];
                                    const itemList = entry[1];

                                    const categories_time = {};
                                    for (const item of itemList) {
                                        if (item.FACTORY_DATE in categories_time) {
                                            categories_time[item.FACTORY_DATE].push(item);
                                        } else {
                                            categories_time[item.FACTORY_DATE] = [item];
                                        }
                                    }

                                    return (
                                        <Accordion
                                            TransitionComponent={Slide}
                                            TransitionProps={slideProps}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {Object.entries(categories_time).map((entry) => {
                                                    const category_list = entry[0];
                                                    const item_list = entry[1];
                                                    return (
                                                        <Accordion
                                                            TransitionComponent={Slide}
                                                            TransitionProps={slideProps}
                                                        >
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>{category_list}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                {item_list.map((item) => {

                                                                    return <Card sx={{
                                                                        p: 1,
                                                                        margin: 'auto',
                                                                        //maxWidth: 1500,
                                                                        flexGrow: 1,
                                                                        backgroundColor: (theme) =>
                                                                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                                                                    }}>
                                                                        <Grid item xs={12} sm container>
                                                                            <Grid item xs container direction="column" spacing={1} style={{ position: "relative", top: "0vw" }}>
                                                                                <Grid item xs>
                                                                                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                        <Typography style={{ fontSize: 18, fontWeight: 'bold' }} gutterBottom>
                                                                                            {item.MATERIAL_DESCRIPTION}&nbsp;&nbsp;&nbsp;
                                                                                        </Typography>

                                                                                    </Box>

                                                                                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }} textAlign="left" >
                                                                                        <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px', width: '80px' }} onClick={() => { }}>
                                                                                            {item.LINE}
                                                                                        </Typography>
                                                                                        <Button style={{ backgroundColor: '#E8AB6D', color: "black", fontSize: 18, height: '20px' }} onClick={() => { }}>
                                                                                            {item.SHIFT}
                                                                                        </Button>
                                                                                    </Box>

                                                                                    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', height: '5%' }} textAlign="left" >

                                                                                    </Box>

                                                                                    <Typography style={{ fontSize: 15, width: '1000%' }} gutterBottom>
                                                                                        {(item.AT_REJECTED_TIMESTAMP).split("Z")[0]}
                                                                                    </Typography>
                                                                                    <Typography style={{ fontSize: 18 }} gutterBottom>
                                                                                        ชุดนวด: {item.BATCH}
                                                                                    </Typography>
                                                                                    <Typography gutterBottom style={{ color: "black", fontSize: 18, height: '20px' }}>
                                                                                        {item.ACTUAL_BARREL_WEIGHT} kg.
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs container spacing={1} style={{ position: "relative", top: "1vw" }} textAlign="right">
                                                                                <Grid item xs={12} md={12} lg={12} xl={12}>
                                                                                    <ButtonGroup orientation="vertical" aria-label="Vertical button group">
                                                                                        <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} >IN STOCK</Button>
                                                                                        <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} >PROCESSING</Button>
                                                                                        <Button style={{ height: '30px', width: '85px', color: '#a9abaa', fontSize: 15 }} >COMPLETED</Button>
                                                                                        <Button style={{ height: '30px', width: '85px', fontWeight: 'bold', color: '#eb2626', backgroundColor: '#c7c9c8', fontSize: 15 }} >REJECT</Button>
                                                                                    </ButtonGroup>
                                                                                </Grid>

                                                                            </Grid>
                                                                        </Grid>
                                                                    </Card>
                                                                })}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    );
                                                })}
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })}
                            </div>
                        </Box>
                    </Grid>
                </Grid>}

            <Dialog
                open={openPopupTimeout}
                onClose={handleClosePopupTimeout}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Unable to load data. Please try again.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { handleClosePopupTimeout(); window.location.reload(false); }} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}