import { useRef, useEffect, useState } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { apiUrl } from '../../../constants'
import axios from 'axios'
import db from "firebase-config"
import moment from 'moment-timezone';
import GaugeChart from "components/chart/GaugeChart"
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "theme";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import HeaderDetail from "components/header/HeaderDetail";
import GridToGiveawayDetail from "components/grid/GridToGivawayDetail";
import { useNavigate } from 'react-router-dom';
import PopupGiveawayLine8 from "components/model/PopupGiveawayLine8";

const ItemGiveaway = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(0),
    textAlign: 'center',
    border: "2px dashed #4a4a49",
    borderRadius: 30,
    //height: '102%'

}));

const ItemActual = styled(Paper)(({ theme }) => ({
    backgroundColor: '#4a4a49',
    padding: theme.spacing(0.2),
    textAlign: 'center',
    //border: "3px dashed #4a4a49",
    borderRadius: 10,
    //height: '102%'

}));


const GiveawayLine8P1 = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('DD MMM YY');
    const [no_data, setNo_data] = useState(false);
    const [giveawayP1, setGiveawayP1] = useState({
        WARNING: "",
        LINE: "",
        SHIFT: "",
        SKU: "",
        TARGET_KG: "",
        ACTUAL_KG: "",
        TARGET_PACK: "",
        ACTUAL_PACK: "",
        REMAINING_PACK: "",
        REJECT_PACK: "",
        PACK_SIZE_STD: "",
        SETTING_PACK_SIZE: "",
        AVG_PACK_SIZE: "",
        ACTUAL_PERCENT_GIVEAWAY: "",
        COST_SAVING_PACK: "",
        TARGET_GIVEAWAY: "",
        MIN_GIVEAWAY: ""
    });
    const [keeplive, setkeeplive] = useState([]);

    setTimeout(() => {
        navigate('/giveaway/line8/p2');
    }, 30000);

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${apiUrl.GIVEAWAY_LINE8_PAGE1}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        sessionStorage.setItem('warning_line8', response.data[0].WARNING);
                        sessionStorage.setItem('line_line8', response.data[0].LINE);
                        sessionStorage.setItem('sku_line8', response.data[0].SKU);
                        sessionStorage.setItem('target_kg_line8', response.data[0].TARGET_KG);
                        sessionStorage.setItem('actual_kg_line8', response.data[0].ACTUAL_KG);
                        sessionStorage.setItem('target_pack_line8', response.data[0].TARGET_PACK);
                        sessionStorage.setItem('actual_pack_line8', response.data[0].ACTUAL_PACK);
                        sessionStorage.setItem('remaining_pack_line8', response.data[0].REMAINING_PACK);
                        sessionStorage.setItem('reject_pack_line8', response.data[0].REJECT_PACK);
                        sessionStorage.setItem('pack_size_std_line8', response.data[0].PACK_SIZE_STD);
                        sessionStorage.setItem('setting_pack_size_line8', response.data[0].SETTING_PACK_SIZE);
                        sessionStorage.setItem('avg_pack_size_line8', response.data[0].AVG_PACK_SIZE);
                        sessionStorage.setItem('actual_percent_giveaway_line8', response.data[0].ACTUAL_PERCENT_GIVEAWAY);
                        sessionStorage.setItem('cost_saving_pack_line8', response.data[0].COST_SAVING_PACK);
                        sessionStorage.setItem('target_giveaway_line8', response.data[0].TARGET_GIVEAWAY);
                        sessionStorage.setItem('min_giveaway_line8', response.data[0].MIN_GIVEAWAY);
                        setGiveawayP1({
                            WARNING: response.data[0].WARNING,
                            LINE: response.data[0].LINE,
                            SHIFT: response.data[0].SHIFT,
                            SKU: response.data[0].SKU,
                            TARGET_KG: response.data[0].TARGET_KG,
                            ACTUAL_KG: response.data[0].ACTUAL_KG,
                            TARGET_PACK: response.data[0].TARGET_PACK,
                            ACTUAL_PACK: response.data[0].ACTUAL_PACK,
                            REMAINING_PACK: response.data[0].REMAINING_PACK,
                            REJECT_PACK: response.data[0].REJECT_PACK,
                            PACK_SIZE_STD: response.data[0].PACK_SIZE_STD,
                            SETTING_PACK_SIZE: response.data[0].SETTING_PACK_SIZE,
                            AVG_PACK_SIZE: response.data[0].AVG_PACK_SIZE,
                            ACTUAL_PERCENT_GIVEAWAY: response.data[0].ACTUAL_PERCENT_GIVEAWAY,
                            COST_SAVING_PACK: response.data[0].COST_SAVING_PACK,
                            TARGET_GIVEAWAY: response.data[0].TARGET_GIVEAWAY,
                            MIN_GIVEAWAY: response.data[0].MIN_GIVEAWAY,
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }

    }, []);

    useEffect(() => {
        const subscriber = db
            .collection("meskr_keeplive")
            .onSnapshot((querySnapshot) => {
                const newData = [];
                querySnapshot.forEach((doc) => {
                    newData.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                if (JSON.stringify(newData) !== JSON.stringify(keeplive)) {
                    setkeeplive(newData);
                    let mul1 = newData.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line8.0NoError"));
                    sessionStorage.setItem('multihead_line8', mul1?.VALUE);
                    let c_w = newData.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line8.Status"));
                    sessionStorage.setItem('c_w_line8', c_w?.VALUE);
                    let x_ray = newData.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line8.Status"));
                    sessionStorage.setItem('x_ray_line8', x_ray?.VALUE);
                    let m_d = newData.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line8.Status"));
                    sessionStorage.setItem('m_d_line8', m_d?.VALUE);
                }


            });

        // return cleanup function
        return () => subscriber();
    }, [keeplive]);



    const multihead1 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line8.0NoError"));
    const check_weihgt = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line8.Status"));
    const xray = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line8.Status"));
    const md = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line8.Status"));

    const scale = (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : ((giveawayP1.SETTING_PACK_SIZE - giveawayP1.PACK_SIZE_STD) / giveawayP1.PACK_SIZE_STD) * 100) * 2

    const getColorGiveaway = () => {
        let color;
        try {
            if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.50) {
                color = '#37af9b'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.75) {
                color = '#56cab7'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.75 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#96ded2'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.25) {
                color = '#f5c842'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.50) {
                color = '#fcad00'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.75) {
                color = '#ec7728'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.75) {
                color = '#e20000'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.50) {
                color = '#37af9b'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.75) {
                color = '#56cab7'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.75 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#96ded2'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.25) {
                color = '#f5c842'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.50) {
                color = '#fcad00'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.75) {
                color = '#ec7728'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.75) {
                color = '#e20000'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.5) {
                color = '#2c8c7c'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.5 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#37af9b'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.5) {
                color = '#56cab7'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.5 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 2) {
                color = '#96ded2'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 2.5) {
                color = '#f5c842'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 2.5 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 3) {
                color = '#fcad00'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 3 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 3.5) {
                color = '#ec7728'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 3.5) {
                color = '#e20000'
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const scale_old = (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (Number((sessionStorage.getItem('setting_pack_size_line8')) - Number(sessionStorage.getItem('pack_size_std_line8'))) / Number(sessionStorage.getItem('pack_size_std_line8'))) * 100) * 2

    const getColorGiveawayOld = () => {
        let color;
        try {
            if (scale_old == 0 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) >= 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 0.25 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 0.50) {
                color = '#37af9b'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 0.50 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 0.75) {
                color = '#56cab7'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 0.75 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 1) {
                color = '#96ded2'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 1 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 1.25) {
                color = '#f5c842'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 1.25 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 1.50) {
                color = '#fcad00'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 1.50 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 1.75) {
                color = '#ec7728'
            } else if (scale_old == 0 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 1.75) {
                color = '#e20000'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) >= 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 0.25 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 0.50) {
                color = '#37af9b'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 0.50 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 0.75) {
                color = '#56cab7'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 0.75 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 1) {
                color = '#96ded2'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 1 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 1.25) {
                color = '#f5c842'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 1.25 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 1.50) {
                color = '#fcad00'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 1.50 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 1.75) {
                color = '#ec7728'
            } else if (scale_old == 2 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 1.75) {
                color = '#e20000'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) >= 0 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 0.5) {
                color = '#2c8c7c'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 0.5 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 1) {
                color = '#37af9b'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 1 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 1.5) {
                color = '#56cab7'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 1.5 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 2) {
                color = '#96ded2'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 2 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 2.5) {
                color = '#f5c842'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 2.5 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 3) {
                color = '#fcad00'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 3 && (sessionStorage.getItem('warning') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) <= 3.5) {
                color = '#ec7728'
            } else if (scale_old == 4 && (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 0 : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? 0 : Number(sessionStorage.getItem('actual_percent_giveaway_line8')))) > 3.5) {
                color = '#e20000'
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    const getValidValue = (value) => {
        return value === undefined || value === null || value === '-' ? 0 : Number(value);
    }



    return (
        <>
            {giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' || sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' || multihead1?.VALUE == false || check_weihgt?.VALUE == 0 || xray?.VALUE == 0 || md?.VALUE == 0 || (sessionStorage.getItem('multihead_line8') == 'true') == false  || Number(sessionStorage.getItem('c_w_line8')) == 0 || Number(sessionStorage.getItem('x_ray_line8')) == 0 || Number(sessionStorage.getItem('m_d_line8')) == 0 ? <PopupGiveawayLine8 /> :
                <Helmet>
                    <title>Giveaway Line8 Page1 | MES</title>
                </Helmet>
            }
            <Grid item xs={12}>
                <HeaderDetail
                    line={no_data == 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 'Line8' : giveawayP1.LINE) : 'Line8'}
                    sku={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : giveawayP1.SKU) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : sessionStorage.getItem('sku_line8'))}
                    timestr={timestr}
                    icon={giveawayP1.SHIFT === "N" ? (<DarkModeOutlinedIcon style={{ color: '#ffffff' }} />) : (<LightModeOutlinedIcon style={{ color: '#ffffff' }} />)}
                />
            </Grid>

            <Grid item xs={12} sm={12} style={{ backgroundColor: colors.while[100], paddingTop: 5, paddingRight: 10, paddingLeft: 10 }}>
                <GridToGiveawayDetail
                    target_kg={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : Math.round(Number(giveawayP1.TARGET_KG)).toLocaleString()) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : Math.round(Number(sessionStorage.getItem('target_kg_line8'))).toLocaleString())}
                    actual_kg={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : Math.round(Number(giveawayP1.ACTUAL_KG)).toLocaleString()) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : Math.round(Number(sessionStorage.getItem('actual_kg_line8'))).toLocaleString())}
                    target_pack={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.TARGET_PACK == '-' ? '-' : Math.round(Number(giveawayP1.TARGET_PACK)).toLocaleString())) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('target_pack_line8') == '-' ? '-' : Math.round(Number(sessionStorage.getItem('target_pack_line8'))).toLocaleString()))}
                    actual_pack={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.ACTUAL_PACK == '-' ? '-' : Math.round(Number(giveawayP1.ACTUAL_PACK)).toLocaleString())) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('actual_pack_line8') == '-' ? '-' : Math.round(Number(sessionStorage.getItem('actual_pack_line8'))).toLocaleString()))}
                    remaining_pack={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.REMAINING_PACK == '-' ? '-' : Math.round(Number(giveawayP1.REMAINING_PACK)).toLocaleString())) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('remaining_pack_line8') == '-' ? '-' : Math.round(Number(sessionStorage.getItem('remaining_pack_line8'))).toLocaleString()))}
                    reject_pack={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.REJECT_PACK == '-' ? '-' : Math.round(Number(giveawayP1.REJECT_PACK)).toLocaleString())) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('reject_pack_line8') == '-' ? '-' : Math.round(Number(sessionStorage.getItem('reject_pack_line8'))).toLocaleString()))}
                />
            </Grid>

            <Grid item xs={12} sm={12} style={{ backgroundColor: colors.while[100], paddingTop: 5, paddingRight: 10, paddingLeft: 10 }}>
                <ItemGiveaway>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                        <Grid item xs={3.9}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                    <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold' }} >
                                        Pack Size
                                    </Typography>
                                    <Typography style={{ color: colors.black[100], fontSize: 20, fontWeight: 'bold', paddingTop: 1 }} >
                                        &nbsp;(g/bag)
                                    </Typography>
                                </Box>

                                <Typography style={{ color: colors.green[100], fontSize: 24, fontWeight: 'bold' }} >
                                    Avg. Actual
                                </Typography>
                                <Typography style={{ color: colors.green[100], fontSize: 32, fontWeight: 'bold', lineHeight: "6vh" }} >
                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.AVG_PACK_SIZE == '-' ? '-' : Number(giveawayP1.AVG_PACK_SIZE).toLocaleString(undefined, { maximumFractionDigits: 2 }))) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('avg_pack_size_line8') == '-' ? '-' : Number(sessionStorage.getItem('avg_pack_size_line8')).toLocaleString(undefined, { maximumFractionDigits: 2 })))}
                                </Typography>

                                <Typography style={{ color: colors.grey[100], fontSize: 20, fontWeight: 'bold', lineHeight: "20vh" }} >
                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.PACK_SIZE_STD == 'ไม่มีข้อมูล Std. Pack ในระบบ' ? 'ไม่มีข้อมูล Std. Pack ในระบบ' : 'Std. ' + Math.round(Number(giveawayP1.PACK_SIZE_STD)).toLocaleString())) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : 'Std. ' + Math.round(Number(sessionStorage.getItem('pack_size_std_line8'))).toLocaleString())}/Setting {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.SETTING_PACK_SIZE == '-' ? '-' : Math.round(Number(giveawayP1.SETTING_PACK_SIZE)).toLocaleString())) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('setting_pack_size_line8') == '-' ? '-' : Math.round(Number(sessionStorage.getItem('setting_pack_size_line8'))).toLocaleString()))}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={0.2} style={{ borderLeft: "1px dashed #ffffff", height: '45vh', position: "relative", top: '1vw' }} >

                        </Grid>

                        <Grid item xs={7.9}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: colors.black[100], fontSize: 28, fontWeight: 'bold' }} >
                                    Giveaway
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                    <Grid item xs={3} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.grey[100], fontSize: 22, lineHeight: "4vh" }} >
                                                Forecast
                                            </Typography>
                                            <Typography style={{ color: colors.grey[100], fontSize: 28, fontWeight: 'bold', lineHeight: "10vh" }} >
                                            {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.TARGET_GIVEAWAY == '-' ? '-' : Math.round(Number(giveawayP1.TARGET_GIVEAWAY)).toLocaleString())) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('target_giveaway_line8') == '-' ? '-' : Math.round(Number(sessionStorage.getItem('target_giveaway_line8'))).toLocaleString()))}%
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 6 }} >
                                            <Typography style={{ color: colors.grey[100], fontSize: 22 }} >
                                                Best Practice
                                            </Typography>
                                            <Typography style={{ color: colors.grey[100], fontSize: 28, fontWeight: 'bold', lineHeight: "6vh" }} >
                                            {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.MIN_GIVEAWAY == '-' ||  giveawayP1.MIN_GIVEAWAY == 'None' ? '-' : (Math.round(Number(giveawayP1.MIN_GIVEAWAY) * 100) / 100).toFixed(2))) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('min_giveaway_line8') == '-' || sessionStorage.getItem('min_giveaway_line8') == 'None' ? '-' : (Math.round(Number(sessionStorage.getItem('min_giveaway_line8')) * 100) / 100).toFixed(2)))}%
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 15, paddingLeft: 10 }} >
                                            {giveawayP1.ACTUAL_PERCENT_GIVEAWAY !== undefined && giveawayP1.SETTING_PACK_SIZE !== undefined && giveawayP1.PACK_SIZE_STD !== undefined ? (
                                                <GaugeChart
                                                    value={getValidValue(no_data === 1 ? giveawayP1.ACTUAL_PERCENT_GIVEAWAY : sessionStorage.getItem('actual_percent_giveaway_line8'))}
                                                    setting={getValidValue(no_data === 1 ? giveawayP1.SETTING_PACK_SIZE : sessionStorage.getItem('setting_pack_size_line8'))}
                                                    std={getValidValue(no_data === 1 ? giveawayP1.PACK_SIZE_STD : sessionStorage.getItem('pack_size_std_line8'))}
                                                    width={230}
                                                    height={60}
                                                />) : ''}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "2vh" }} >
                                                Actual
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }} >
                                            <ItemActual>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: no_data == 1 ? getColorGiveaway() : getColorGiveawayOld(), fontSize: 32, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                    {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? '-' : (Math.round(Number(giveawayP1.ACTUAL_PERCENT_GIVEAWAY) * 100) / 100).toFixed(2))) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('actual_percent_giveaway_line8') == '-' ? '-' : (Math.round(Number(sessionStorage.getItem('actual_percent_giveaway_line8')) * 100) / 100).toFixed(2)))}
                                                    </Typography>
                                                    <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', paddingTop: 5, lineHeight: "10vh" }} >
                                                        &nbsp;%
                                                    </Typography>
                                                </Box>
                                            </ItemActual>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 5, paddingRight: 10, paddingLeft: 10 }}>
                                            <ItemActual>
                                                <Typography style={{ color: no_data == 1 ? getColorGiveaway() : getColorGiveawayOld(), fontSize: 32, fontWeight: 'bold', lineHeight: "10vh" }} >
                                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.COST_SAVING_PACK == '-' ? '-' : Math.round(Number(giveawayP1.COST_SAVING_PACK)).toLocaleString())) : sessionStorage.getItem('warning_line8') == null ? '-' : (sessionStorage.getItem('warning_line8') == 'ไม่ตรงแผนการผลิต' ? '-' : (sessionStorage.getItem('cost_saving_pack_line8') == '-' ? '-' : Math.round(Number(sessionStorage.getItem('cost_saving_pack_line8'))).toLocaleString()))}
                                                </Typography>
                                                <Typography style={{ color: colors.black[100], fontSize: 22, fontWeight: 'bold', lineHeight: "3vh", position: "relative", top: "-0.5vw" }} textAlign="right" >
                                                    bag&nbsp;
                                                </Typography>
                                            </ItemActual>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ItemGiveaway>
            </Grid>
        </>
    )
}

export default GiveawayLine8P1
